const intakeInfo = {
    currentIntake: {
        startDate: "TBA",
        applicationDeadline: "TBA",
        isClosed: true
    }
};

export const isApplicationOpen = () => {
    return false; // Always return false since applications are closed
};

export default intakeInfo; 